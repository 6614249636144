/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.bg-video-wrap {

}
/*.bg-video-wrap {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}*/
